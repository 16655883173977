/**
 * @description 所有全局配置的状态管理，如无必要请勿修改
 */
const state = () => ({

})
const getters = {
}
const mutations = {
}
const actions = {
}
export default { state, getters, mutations, actions }
