import { createRouter, createWebHashHistory } from 'vue-router'
import Layout from '@/views/index/index'

const pre = 'main'
const routes = [
  {
    path: '/',
    name: 'main',
    meta: { title: '首页' },
    hidden: true,
    redirect: `/index`,
    component: Layout,
    children: [
      {
        path: '/index',
        name: `${pre}-index`,
        meta: {
          title: '首页',
          activeMenu: `/index`
        },
        components: {
          default: () => import('@/views/home/index'),

        }
      },
      {
        path: '/product',
        name: `${pre}-product`,
        meta: {
          title: '产品',
          activeMenu: `/product`
        },
        components: {
          default: () => import('@/views/product/index'),
          // default: () => import('@/views/home/index'),
        },
        // redirect: `/index`,
        // redirect: `/product`,
        children: [
          {
            path: `project`,
            name: `${pre}-project`,
            meta: {
              title: '审阅项目',
              activeMenu: `/product`
            },
            components: {
              default: () => import('@/views/project/index'),
            }
          },
          {
            path: `cloud`,
            name: `${pre}-cloud`,
            meta: {
              title: '素材云盘',
              activeMenu: `/product`
            },
            components: {
              default: () => import('@/views/cloud/index'),
            }
          },
          {
            path: 'material',
            name: `${pre}-material`,
            meta: {
              title: '版权素材',
              activeMenu: `/product`
            },
            components: {
              // default: () => import('@/views/dispute/index'),
              default: () => import('@/views/material/index'),
            }
          },
          {
            path: `tool`,
            name: `${pre}-tool`,
            meta: {
              title: '效率工具',
              activeMenu: `/product`
            },
            components: {
              default: () => import('@/views/tool/index'),
            }
          },
          {
            path: `work`,
            name: `${pre}-work`,
            meta: {
              title: '灵活用工',
              activeMenu: `/product`
            },
            components: {
              default: () => import('@/views/work/index'),
            }
          },
        ]
      },
      {
        path: '/price',
        name: `${pre}-price`,
        meta: {
          title: '价格',
          activeMenu: `/price`
        },
        components: {
          default: () => import('@/views/price/index'),
        }
      },
      {
        path: '/about',
        name: `${pre}-about`,
        meta: {
          title: '关于我们',
          activeMenu: `/about`
        },
        components: {
          default: () => import('@/views/about/index'),
          // default: () => import('@/views/home/index'),
        }
      },
    ]
  },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
