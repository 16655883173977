import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index.js'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// 公共样式
import '@/assets/css/index.css'
import device from 'current-device'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

if(!device.desktop()){
  // window.location.href = '/h5/'
}

const app = createApp(App)
// app.use(ElementPlus, {
//   locale: zhCn,
// })
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.use(store).use(router).use(ElementPlus).mount('#app')
