/**
 *  导入所有 vuex 模块，自动加入namespaced:true，用于解决vuex命名冲突。
 */

//  dispatch：含有异步操作，例如向后台提交数据，写法： this.$store.dispatch('action里的方法名',值)
//  commit：同步操作，写法：this.$store.commit('mutations里的方法名',值)
import { createStore } from 'vuex'

const modules = {}
const files = require.context('./modules', false, /\.js$/)
files.keys().forEach((key) => {
  modules[key.replace(/(modules|\/|\.|js)/g, '')] = {
    ...files(key).default,
    namespaced: true
  }
})
const store = createStore({ modules })
// console.log(modules, store)
// export function setupStore (app) {
//   app.use(store)
// }

export default store
