/**
 * @description 所有全局配置的状态管理，如无必要请勿修改
 */
const state = () => ({
  userInfo: {
    userName: '',
    userPhone: '',
    userEmaeli: ''
  },
  token: '',
})
const getters = {
  userInfo: (state) => state.userInfo,
  token: (state) => state.token
}
const mutations = {
  setUserInfo (state, data) {
    Object.keys(data).forEach((key) => {
      state.userInfo[key] = data[key]
    })
    // state.userInfo = data
  },
  setToken (state, data) {
    state.token = data
  }
}
const actions = {
  // setUserInfo ({ commit }, data) {
  //   commit('setUserInfo', data)
  // },
  getUserInfoData ({ commit }, data) {
    commit('setUserInfo', data)
  }
  // setToken ({ commit }, data) {
  //   commit('setToken', data)
  // }
}
export default { state, getters, mutations, actions }
