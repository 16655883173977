<template>
  <div>
    <el-scrollbar ref="scroll" id="scroll" class="scroll">
      <div class="navbar-wrapper main-box">
        <div class="top" id="top">
          <div class="yyth-text" style="position: absolute;left: -5000px;color: transparent;">影音同画影音同画</div>
          <div style="display: flex;">
            <div class="logo" @click="router.push('/index')"><img src="@/assets/logo/logoName.png"></div>
            <nav ref="navigate" class="navigate">
              <template v-for="(item,index) in menus" :key="index">
                <div @click=goToPath(item) class="menu-item" :class="{'action': item.path === activePath}"  v-if="item.name && index !== menus.length-1">
                  <div class="menu-item-name pc-menu" @mouseover="handleHover(item)" @mouseout="handleOut(item)">
                    <div class="name-item">
                      {{ item.name }}
                      <img src="@/assets/home/icon_top.png" v-if="item.list" :class="[showList ? 'move' : 'reverse', 'icon-top']">
                    </div>
                    <div v-show="showList && index===1" class="menu-item-child" @mouseout="remove">
                      <el-row class="menu-card">
                        <template v-for="(cl,i) in menus[1].list">
                          <el-col :span="12" :xs="24" @click.stop="goToPath(cl)">
                            <div class="menu-title">{{ cl.name }}</div>
                            <div class="menu-info">{{ cl.context }}</div>
                          </el-col>
                        </template>
                      </el-row>
                    </div>
                  </div>
                </div>
                <div class="name-item2" v-else>
                  {{ item.name }}
                </div>
              </template>
              <div class="right-login">
                <div class="btn">
                  <div class="login" @click="toWorkbench">工作台</div>
                  <div class="regist" @click="toLogin">登录/注册</div>
                </div>
              </div>
            </nav>
          </div>
          <div class="list-drag" @click.stop="showMenuList()">
            <label>
                <span class="menu-icon">
                    <svg viewBox="0 0 18 15" width="18px" height="15px">
                        <path fill="white" d="M18,1.484c0,0.82-0.665,1.484-1.484,1.484H1.484C0.665,2.969,0,2.304,0,1.484l0,0C0,0.665,0.665,0,1.484,0 h15.031C17.335,0,18,0.665,18,1.484L18,1.484z"/>
                        <path fill="white" d="M18,7.516C18,8.335,17.335,9,16.516,9H1.484C0.665,9,0,8.335,0,7.516l0,0c0-0.82,0.665-1.484,1.484-1.484 h15.031C17.335,6.031,18,6.696,18,7.516L18,7.516z"/>
                        <path fill="white" d="M18,13.516C18,14.335,17.335,15,16.516,15H1.484C0.665,15,0,14.335,0,13.516l0,0 c0-0.82,0.665-1.484,1.484-1.484h15.031C17.335,12.031,18,12.696,18,13.516L18,13.516z"/>
                   </svg>
              </span>
            </label>
          </div>
          <div class="right">
            <div class="btn">
              <div class="login" @click="toWorkbench">工作台</div>
              <div class="regist" @click="toLogin">登录/注册</div>
            </div>
          </div>
        </div>
      </div>

      <div class="activate-menu" v-show="show">
        <div :style="{'height': menuHeight}" style="transition: all 0.3s ease-out;">
          <el-tree :data="menus" :props="treeProps" @node-click="handleMenuClick">
            <template #default="{ node, data }">
<!--              <div class="custom-tree-node" :class="{'action': data.path === phonePath}" v-if="data.type!=='botton'" >-->
              <div class="custom-tree-node" :class="{'action': data.path === phonePath}" >
                <div >{{ node.label }}</div>
                <div v-if="data.list">
                  <el-icon>
                    <ArrowDown/>
                  </el-icon>
                </div>
              </div>
<!--              手机端菜单暂时隐藏登录-->
<!--              <div v-else>-->
<!--                <div class="btn btn-2">-->
<!--                  <div class="login" @click="toWorkbench">工作台</div>-->
<!--                  <div class="regist" @click="toLogin">登录/注册</div>-->
<!--                </div>-->
<!--              </div>-->
            </template>
          </el-tree>
        </div>
      </div>

      <router-view/>

      <div class="box-bottom">
        <div class="btm">
          <div class="btm-padding">
            <el-row>
              <el-col :span="3" :xs="24" :sm="12" :md="4" :lg="3" :xl="3" v-for="item in bottomList" :key="item">
                <div class="b-list">
                  <div class="b-title">{{ item.title }}</div>
                  <div class="b-list-main">
                    <span v-for="(it,index) in item.list" class="b-li" :key="index" @click="goUrl(it)">
                      {{ it.name }}</span>
                  </div>
                </div>

              </el-col>
              <el-col :span="12" :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                <div class="b-list">
                  <div class="b-title">联系我们</div>
                  <div class="flex">
                    <div style="display: flex">
                      <div class="code">
                        <div>关注官方公众号</div>
                        <img src="@/assets/home/QRcode/code1.png">
                      </div>
                      <div class="code">
                        <div>加入社群</div>
                        <img src="@/assets/home/QRcode/code2.png">
                      </div>
                    </div>

                    <div class="code">
                      <div>商务合作</div>
                      <img src="@/assets/home/QRcode/code4.png" style="width: 131px;border-radius: 6px;margin-top: 4px;margin-left: 4px;">
                    </div>
                  </div>

                </div>
              </el-col>
            </el-row>
          </div>
          <div class="footer">
            <div class="copyright">Copyright © 2022 yingyintonghua.com 广西肆肆壹零零文化科技有限公司 All Rights Reserved.</div>
            <div class="link">
              <a class="item" href="https://beian.miit.gov.cn/" target="_blank">桂ICP备2022005950号</a>
              <a class="item"
                 href="http://www.beian.gov.cn/portal/registerSystemInfo?spm=a2c6h.13706215.0.0.15e437d59W5pzo"
                 target="_blank">桂公网安备：45010502000802</a>
              <a class="item" href="https://mypublic.yingyintonghua.com/44100/www.jpg"
                 target="_blank">桂网文(2022)2411-445号</a>
              <a class="item" href="https://dxzhgl.miit.gov.cn/#/home" target="_blank">增值电信业务经营许可证：桂B2-20220892</a>
              <a class="item" href="https://mypublic.yingyintonghua.com/44100/500-1K.jpg" target="_blank">营业执照</a>
              <a class="item" href="https://www.12377.cn/index.html" target="_blank">中国互联网举报中心</a>
            </div>
          </div>
        </div>
        <div class="yyth-text" style="position: absolute;left: -5000px;color: transparent;">影音同画影音同画影音同画影音同画影音同画影音同画</div>
      </div>
    </el-scrollbar>

  </div>
</template>
<script setup>
import { defineComponent, watchEffect, ref, computed, onMounted, watch } from 'vue'

import { useRoute, useRouter } from 'vue-router'
import { useWindowSize } from '@vueuse/core'
import { logo } from '@/config'

const route = useRoute()
const activePath = ref('')
const phonePath = ref('')
onMounted(() => {
  activePath.value = route.meta.activeMenu
  phonePath.value = route.path
  document.addEventListener('click', (e) => {
    if (e.target.className !== 'el-tree') {
      show.value = false
    }
  })
})
watch(route, () => {
  activePath.value = route.meta.activeMenu
  phonePath.value = route.path
  backTop()
})

const show = ref(false)
const navigate = ref(null)
const menuHeight = ref('200px')

const treeProps = {
  children: 'list',
  label: 'name'
}
const handleMenuClick = (item) => {
  if (item.path === '/product') {
    menuHeight.value = menuHeight.value === '400px' ? '200px' : '400px'
  }
  if (item.path && item.path !== '/product') {
    router.push(item.path)
    backTop()
    show.value = false
  }
}

function showMenuList () {
  show.value = !show.value
}

function toWorkbench () {
  window.open('/workbench/', '_blank')
}
function toLogin () {
  window.open('/workbench/#/login', '_blank')
}

function goUrl (item) {
  if (item.route === '/') { return }
  if (item.type === 'now') {
    router.push(item.route)
    backTop()
  }else if(item.type === 'blankUrl'){
    window.open(item.url,'_blank')
  }else {
    window.open('/workbench/#' + item.route, '_blank')
  }
}

const menus = ref([
  {
    name: '首页',
    path: '/index'
  },
  {
    name: '产品',
    path: '/product',
    isShow: false,
    list: [
      {
        path: '/product/project',
        name: '审阅项目',
        context: '创新的在线审批方式，沟通交付快3倍'
      }, {
        path: '/product/material',
        name: '正版素材',
        context: '优质低价正版素材，无惧版权风险'
      }, {
        path: '/product/cloud',
        name: '素材云盘',
        context: '云端无限存储，不限流不限速'
      }, {
        path: '/product/tool',
        name: '效率工具',
        context: '人工智能，高效创作输出'
      }, {
        path: '/product/work',
        name: '灵活用工',
        context: '灵活用工新模式，智能撮合交易'
      }
    ]
  },
  {
    name: '价格',
    path: '/price'
  },
  {
    name: '关于我们',
    path: '/about'
  },
  {
    name: '授权查询',
    id: null
    // path: '/dispute'
  }
  // {
  // name: '1',
  // type: 'botton'
  // path: '/dispute'
  // }
])

const bottomList = [
  {
    title: '公司',
    list: [{
      route: '/index',
      type: 'now',
      name: '首页'
    },
    {
      route: '/price',
      type: 'now',
      name: '价格'
    },
    {
      route: '/about',
      type: 'now',
      name: '关于我们'
    }
    ]
  },
  {
    title: '产品',
    list: [{
      route: '/product/project',
      type: 'now',
      name: '审阅项目'
    },
    {
      route: '/product/cloud',
      type: 'now',
      name: '素材云盘'
    },
    {
      route: '/product/work',
      type: 'now',
      name: '灵活用工'
    },
    {
      route: '/product/material',
      type: 'now',
      name: '正版素材'
    },
    {
      route: '/product/tool',
      type: 'now',
      name: '效率工具'
    },
    {
      route: '/',
      name: '更多功能开发中...'
    }
    ]
  },
  {
    title: '用户服务',
    list: [{
      route: '/help/guide',
      name: '帮助中心'
    },
    {
      route: '/agreement/service',
      name: '服务条款'
    },
    {
      route: '/agreement/privacy',
      name: '隐私政策'
    }
    ]
  },
  {
    title: '下载',
    list: [{
      route: '/help/guide?id=1589877074667081729',
      name: '更新日志'
    },
    {
      url: 'https://www.yingyintonghua.com/b/ZRVZne',
      type: 'blankUrl',
      name: '产品下载'
    }
    ]
  }
]

const router = useRouter()

const showList = ref(false)
const goToPath = (item) => {
  if (!item.path) { return }
  if (item.path === '/product') {
    showList.value = !showList.value
  } else if (item.path !== '/product') {
    router.push(item.path)
    backTop()
    showList.value = false
  }
}

const nowList = ref()
const handleHover = (item) => {
  nowList.value = item.path
  if (item.path === '/product') {
    showList.value = true
  } else {
    showList.value = false
  }
}
const handleOut = (item) => {
  if (item.path === '/product' && nowList.value !== '/product') {
    showList.value = false
  }
}
const remove = () => {
  showList.value = false
  show.value = false
}

const { width } = useWindowSize()

const scroll = ref(null)

// 回到顶部
function backTop () {
  const section = document.getElementById('scroll')
  if (section) {
    section.scrollIntoView()
  }
}
</script>

<style lang="scss">
@import './style.scss';
.scroll {
  height: 100%;
}

.scroll .el-scrollbar__view {
  overflow-x: hidden;
}

</style>
